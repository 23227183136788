import { Stack } from "@chakra-ui/react"
import { HeadFC } from "gatsby"
import React from "react"
import { HeadSEO } from "../components/head/"
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter"

export default function Lojas() {
  return (
    <Stack>
      <iframe
        id="gofind-iframe"
        // class="width-100 height-100 padding-0 margin-0"
        // frameborder="0"
        src="https://verdecampo.pertinhodemim.com"
        width="100%"
        height="100%"
        // style="border: 1px solid #f2f2f2;"
        // allowfullscreen=""
        allow="geolocation"
        style={{
          height: "46rem",
        }}
      ></iframe>
    </Stack>
  )
}

export const Head: HeadFC = () =>
  HeadSEO(capitalizeFirstLetter(`Onde Comprar | Verde Campo`))
